import React from 'react'
import { PageProps } from 'gatsby'

import { GlobeAltIcon, LightningBoltIcon, MailIcon, ScaleIcon } from '@heroicons/react/outline'
import ServiceDetails from '../components/ServiceDetails'
import Footer from '../components/Footer'
import Header from '../components/Header'

const leftFeatureItems = [
  {
    id: 1,
    name: 'Project Management and Procurement',
    description:
      'We work closely with multiple vendors to fit your organisational requisites for ICT Solutions and our experienced team are available to work with you on any requirements you may have regarding the purchase, installation, or upgrade of your ICT infrastructure. Whether you simply need one device or have a larger scale requirement, we can offer value for money and enhance your existing business processes.',
    icon: GlobeAltIcon,
  },
  {
    id: 2,
    name: 'Office Relocation',
    description:
      'With over many years of expertise our trained staff are experienced to support you with all your ICT relocation requirements. We provide a selection of comprehensive services which include: Server relocations, PC and Office IT relocation, Cabling : Auding, Port mapping',
    icon: LightningBoltIcon,
  },
]
const rightFeatureItems = [
  {
    id: 1,
    name: 'Systems Upgrade',
    description:
      'System upgrades are critical to business performance and have additional benefits such as an increase in the lifespan and better efficiency of your computer equipment. We will work with you to build an in-depth understanding of your needs, organisational workflow and how your new or upgraded solutions will integrate into your existing systems. We offer comprehensive support from start to finish - from the initial planning and procurement stages, through to management of the project, completion and sign-off.',
    icon: ScaleIcon,
  },
  {
    id: 2,
    name: 'Infrastructure Build and Design',
    description:
      'We provide comprehensive ICT infrastructure and integrated network technology solutions that support your business and structured to reach their full potential which ensures your day to day operations are never compromised.',
    icon: MailIcon,
  },
]

const ProjectServices = (props: PageProps) => {
  return (
    <div className="bg-white">

      <Header path={props.path} />

      <main>
        <div className="py-12 bg-white overflow-hidden lg:py-12">
          <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
            <svg
              className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
              width={404}
              height={784}
              fill="none"
              viewBox="0 0 404 784"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={784} fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
            </svg>

            <div className="relative">
              <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                PROJECT SERVICES
              </h2>
              <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">
                Sekyee’s Project Services help you align your organisational ICT strategies to ensure a desired outcome
              </p>
            </div>

            <ServiceDetails leftFeatures={leftFeatureItems} rightFeatures={rightFeatureItems} />

            
          </div>
        </div>
      </main>
    
      <Footer />
    </div>
  )
}

export default ProjectServices